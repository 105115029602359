// axios
import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, private-key,Private-Key',
    // 'Access-Control-Allow-Credentials': true,
    // 'Disable-Tunnel-Reminder': 'true',
  },
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  res => res,
  async err => {
    const originalConfig = err.config
    if (originalConfig.url !== '/login' && err.response) {
      // Access Token was expired
      if (err.response.status === 403 && !originalConfig.retry) {
        originalConfig.retry = true
        console.log('renew token')
        try {
          const refreshToken = localStorage.getItem('refreshToken')

          const data = {
            grant_type: 'refresh_token',
            client_id: `${process.env.VUE_APP_CLIENT_ID}`,
            client_secret: `${process.env.VUE_APP_CLIENT_SECRET}`,
            refresh_token: refreshToken,
          }

          const rs = await axios.post(`${process.env.VUE_APP_AUTH_SERVER_URL}/protocol/openid-connect/token`, qs.stringify(data))

          if (rs.response.status !== 400) {
            console.log(rs.data)
            // eslint-disable-next-line camelcase
            const { access_token, refresh_token } = rs.data

            localStorage.setItem('accessToken', access_token)
            localStorage.setItem('refreshToken', refresh_token)

            return axiosIns(originalConfig)
          // eslint-disable-next-line no-else-return
          } else {
            console.log('rs.data00')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')

            localStorage.removeItem('userData')
            localStorage.removeItem('userAbility')

            window.location.href = '/'
          }
        } catch (_error) {
          console.log('rs.data01')

          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')

          localStorage.removeItem('userData')
          localStorage.removeItem('userAbility')

          window.location.href = '/'

          // return Promise.reject(_error)
        }
      }

      if (err.response.status === 401 && !originalConfig.retry) {
        originalConfig.retry = true
        console.log('any other error of authentication')
        console.log(originalConfig)

        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')

        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')

        window.location.href = '#/login'
      }

      if (err.response.status === 400 && !originalConfig.retry) {
        originalConfig.retry = true
        console.log('any other error of authentication')
        console.log(originalConfig)

        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')

        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')

        window.location.href = '/'
      }
    }

    return Promise.reject(err)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
